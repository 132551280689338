import store from "../redux/store/store";
import {HideLoader, ShowLoader} from "../redux/state-slice/settings-slice";
import {BaseURL} from "../helper/config";
import axios from "axios";
import {setToken, setUserDetails} from "../helper/SessionHelper";
import {ErrorToast, SuccessToast} from "../helper/FormHelper";

export async function PartnerLoginRequest(AccessKey){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/PartnerLogin/"+AccessKey;
        let res =await axios.post(URL);
        store.dispatch(HideLoader())
        if(res.status===200 && res.data['status']==="success"){
            setToken(res.data['token']);
            setUserDetails(res.data['data']);
            SuccessToast("Sign in Success")
            return true;
        }
        else {
            ErrorToast("Invalid Email or Password")
            return  false;
        }
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Invalid Email or Password")
        return  false;
    }
}