import React from 'react';
import {Container, Nav, Navbar, Offcanvas} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import logo from "../../assets/images/navlogo.svg"
import {getToken, getUserDetails, removeSessions} from "../../helper/SessionHelper";
import {AiOutlineBook, AiOutlineLogout, AiOutlineUser} from "react-icons/ai";

const AppNavbar = () => {
    return (
        <div>
            {[false, 'sm', 'md', 'lg', 'xl', 'xxl'].map(() => (
                <Navbar key={"lg"} bg="light" expand={"lg"} className="site-nav">
                    <Container>

                        <Navbar.Brand>
                            <img className="nav-logo" alt="" src={logo}/>
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                        <Navbar.Offcanvas id={`offcanvasNavbar-expand-lg`} aria-labelledby={`offcanvasNavbarLabel-expand-lg`} placement="end">

                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                    Offcanvas
                                </Offcanvas.Title>
                            </Offcanvas.Header>

                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <NavLink  className={(navData) => navData.isActive ? "nav-link text-uppercase active" : "nav-link text-uppercase " } to="/" end>Home</NavLink>
                                    <NavLink  className={(navData) => navData.isActive ? "nav-link text-uppercase active" : "nav-link text-uppercase " } to="/about">About</NavLink>
                                    <NavLink  className={(navData) => navData.isActive ? "nav-link text-uppercase active" : "nav-link text-uppercase " } to="/courses">Courses</NavLink>
                                    <NavLink  className={(navData) => navData.isActive ? "nav-link text-uppercase active" : "nav-link text-uppercase " } to="/contact">Contact</NavLink>
                                    {getToken()?
                                        (
                                            <div className="user-dropdown">
                                                <NavLink className="nav-link  rounded-pill text-white themeBG py-2 px-4 ms-md-3" to="/">
                                                    <img className="icon-nav-img" src={getUserDetails()['photo']} alt=""/> {getUserDetails()['firstName']}
                                                </NavLink>
                                                <div className="user-dropdown-content ">
                                                    <div className="mt-4 text-center">
                                                        <img className="icon-nav-img-lg" src={getUserDetails()['photo']} alt=""/>
                                                        <h6 className="fw-bold my-2 bodySmall">{getUserDetails()['firstName']}</h6>
                                                        <hr className="user-dropdown-divider  p-0"/>
                                                    </div>
                                                    <NavLink to="/myCourses" className="side-bar-item">
                                                        <AiOutlineBook className="side-bar-item-icon" />
                                                        <span className="side-bar-item-caption">My Courses</span>
                                                    </NavLink>
                                                    <NavLink to="/profile" className="side-bar-item">
                                                        <AiOutlineUser className="side-bar-item-icon" />
                                                        <span className="side-bar-item-caption">Profile</span>
                                                    </NavLink>
                                                    <a onClick={()=>{removeSessions()}} className="side-bar-item">
                                                        <AiOutlineLogout className="side-bar-item-icon" />
                                                        <span className="side-bar-item-caption">Logout</span>
                                                    </a>
                                                </div>
                                            </div>
                                        ):
                                        ( <NavLink className="nav-link text-uppercase rounded-pill text-white themeBG py-2 px-4 ms-md-3" to="/signin">Sign In</NavLink>)
                                    }
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </div>
    );
};
export default AppNavbar;