import React, {lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const TopRecommended =lazy(() => import('../../components/Site/TopRecommended'));
const TopCourses =lazy(() => import('../../components/Site/TopCourses'));
const CoursesHero =lazy(() => import('../../components/Site/CoursesHero'));
const Testimonial =lazy(() => import('../../components/Site/Testimonial'));
const BrowseCourses =lazy(() => import('../../components/Site/BrowseCourses'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const CoursesPage = () => {
    return (
        <Layout>
            <Suspense fallback={<LazyLoader/>}>
                <CoursesHero/>
                <TopRecommended/>
                <TopCourses/>
                <BrowseCourses/>
                <Testimonial/>
                <Footer/>
            </Suspense>
        </Layout>
    );
};
export default CoursesPage;