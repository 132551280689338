import {createSlice} from "@reduxjs/toolkit";
export const technologySlice=createSlice({
    name:'technology',
    initialState:{
        list:[]
    },
    reducers:{
        SetTechnologyList:(state,action)=>{
            state.list=action.payload
        }
    }
})
export  const {SetTechnologyList}=technologySlice.actions;
export default  technologySlice.reducer;
