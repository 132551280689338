import axios from "axios";
import {ErrorToast, InfoToast, SuccessToast} from "../helper/FormHelper";
import {BaseURL} from "../helper/config";
import store from "../redux/store/store";
import {HideLoader, ShowLoader} from "../redux/state-slice/settings-slice";
import {SetLegalList} from "../redux/state-slice/legal-slice";
import {SetReviewList} from "../redux/state-slice/review-slice";
import {SetRecommendationList} from "../redux/state-slice/recommendation-slice";
import {SetWhy} from "../redux/state-slice/why-slice";
import {SetCourseDetails, SetCourseList, SetCourseListByType, SetSearchList} from "../redux/state-slice/course-slice";
import {SetTechnologyList} from "../redux/state-slice/technology-slice";
import {SetHomeHero} from "../redux/state-slice/homehero-slice";
import {SetContactInfo} from "../redux/state-slice/contactinfo-slice";
import {SetFooterInfoList} from "../redux/state-slice/footerinfo-slice";
import {getToken} from "../helper/SessionHelper";
import {UnAuthorizeRequest} from "./UnAuthorizeRequest";
const AxiosHeader={headers:{"token":getToken()}}

export async function LegalListRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/LegalList";
        let res =await axios.get(URL);
        store.dispatch(SetLegalList(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}


export async function ContactInfoRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/ContactInfoList";
        let res =await axios.get(URL);
        store.dispatch(SetContactInfo(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}


export async function FooterInfoListRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/FooterInfoList";
        let res =await axios.get(URL);
        store.dispatch(SetFooterInfoList(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}



export async function HomeHeroRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/HomeHero";
        let res =await axios.get(URL);
        store.dispatch(SetHomeHero(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}


export async function TechnologyListRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/TechnologyList";
        let res =await axios.get(URL);
        store.dispatch(SetTechnologyList(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}


export async function WhyUsRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/WhyUs";
        let res =await axios.get(URL);
        store.dispatch(SetWhy(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}

export async function RecommendationListRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/RecommendationList";
        let res =await axios.get(URL);
        store.dispatch(SetRecommendationList(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}

export async function ReviewListRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/ReviewList";
        let res =await axios.get(URL);
        store.dispatch(SetReviewList(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}

export async function CourseListRequest(){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/CourseList";
        let res =await axios.get(URL);
        store.dispatch(SetCourseList(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}


export async function CourseSearchRequest(searchKey){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/CourseSearchList/"+searchKey;
        let res =await axios.get(URL);
        store.dispatch(SetSearchList(res.data['data']));
        store.dispatch(HideLoader())
        if(res.data['data'].length===0){
            ErrorToast("No Result Found")
            return false;
        }
        else {
            return true;
        }
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}


export async function CourseDetailsByIDRequest(id){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/CourseDetailsByID/"+id;
        let res =await axios.get(URL);
        store.dispatch(SetCourseDetails(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}




export async function CourseListByTypeRequest(Type){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/CourseListByType/"+Type;
        let res =await axios.get(URL);
        store.dispatch(SetCourseListByType(res.data['data']))
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Something Went Wrong")
        return  false;
    }
}


export async function SubscribeRequest(email){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/CreateSubscriber";
        let PostBody={email:email}
        let res=await axios.post(URL,PostBody);
        store.dispatch(HideLoader());
        if(res.status===200){
            if(res.data['status']==="fail"){
                if(res.data['data']['keyPattern']['email']===1){
                    SuccessToast("Email Already Subscribed, Thank You")
                    return false;
                }
                else{
                    ErrorToast("Something Went Wrong")
                    return false;
                }
            }
            else {
                SuccessToast("Subscribe Success")
                return true;
            }
        }
        else{
            ErrorToast("Something Went Wrong")
            return  false;
        }
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Request Failed ! Try Again")
        return  false;
    }
}


export async function ContactRequest(email, name, mobile, subject, msg){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/CreateContact";
        let PostBody={name:name, email:email, phone:mobile, subject:subject, msg:msg}
        await axios.post(URL,PostBody);
        SuccessToast("Request Completed")
        store.dispatch(HideLoader())
        return true;
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Request Failed ! Try Again")
        return  false;
    }
}


export async function EnrollRequest(courseID, mfsMethod, mfsMobileNo, mfsTrx){
    try {
        store.dispatch(ShowLoader())
        let URL=BaseURL+"/CreateEnroll";
        let PostBody={courseID:courseID, mfsMethod:mfsMethod, mfsMobileNo:mfsMobileNo, mfsTrx:mfsTrx}
        let res=await axios.post(URL,PostBody,AxiosHeader);
        store.dispatch(HideLoader())
        if(res.status===200 && res.data['status']==="success"){
            SuccessToast("Request Completed")
            return true;
        }
        if(res.status===200 && res.data['status']==="associated"){
            InfoToast(res.data['data'])
            return false;
        }
        else {
            ErrorToast("Request Failed ! Try Again")
            return  false;
        }
    }
    catch (e) {
        store.dispatch(HideLoader())
        ErrorToast("Request Failed ! Try Again");
        UnAuthorizeRequest(e)
        return  false;
    }
}

