import React, {useEffect} from 'react';
import {PartnerLoginRequest} from "../../APIRequest/PartnerAPIRequest";
const PartnerLoginPage = () => {
    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        let id= urlParams.get('id')
        debugger;
        (async () => {
            debugger;
            let result= await PartnerLoginRequest(id)
            if(result) {
               window.location.href="/myCourses"
            }
        })();
    },[])
    return (
        <div>

        </div>
    );
};
export default PartnerLoginPage;