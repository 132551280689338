import React, {lazy, useEffect,Suspense} from 'react';
import {CourseClassAssignmentsRequest, CourseClassListRequest, CourseClassResourcesRequest, CourseClassStudyPlansRequest} from "../../APIRequest/ClassRoomAPIRequest";
import SideNavLayout from "../../components/Dashboard/SideNavLayout";
import LazyLoader from "../../components/Site/LazyLoader";
const CoursesClasses =lazy(() => import('../../components/Dashboard/CoursesClasses'));
const CoursesResources =lazy(() => import('../../components/Dashboard/CoursesResources'));
const MyCoursesDetailsPage = () => {
    useEffect(()=>{
        (async () => {
            const urlParams = new URLSearchParams(window.location.search);
            let id= urlParams.get('id')
            await CourseClassListRequest(id)
            await CourseClassAssignmentsRequest(id)
            await CourseClassStudyPlansRequest(id)
            await CourseClassResourcesRequest(id)
        })();
    },[])
    return (
        <SideNavLayout>
            <Suspense fallback={<LazyLoader/>}>
                <CoursesClasses/>
                <CoursesResources/>
                <br/><br/><br/>
            </Suspense>
        </SideNavLayout>
    );
};
export default MyCoursesDetailsPage;