import store from "../redux/store/store";
import { HideLoader, ShowLoader } from "../redux/state-slice/settings-slice";
import { BaseURL } from "../helper/config";
import axios from "axios";
import {
  SetCourseAssignmentList,
  SetCourseClassList,
  SetCourseResourcesList,
  SetCourseStudyPlanList,
  SetEnrolledCourseList,
} from "../redux/state-slice/course-slice";
import { ErrorToast } from "../helper/FormHelper";
import { getToken } from "../helper/SessionHelper";
import { CourseIsNotActivePopUp } from "../helper/SweetPopUp";
import { UnAuthorizeRequest } from "./UnAuthorizeRequest";
const AxiosHeader = { headers: { token: getToken() } };

export async function EnrolledCourseListRequest() {
  try {
    store.dispatch(ShowLoader());
    let URL = BaseURL + "/EnrolledCourseList";
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(SetEnrolledCourseList(res.data["data"]));
    store.dispatch(HideLoader());
    return true;
  } catch (e) {
    store.dispatch(HideLoader());
    ErrorToast("No Enroll Found")
    store.dispatch(SetEnrolledCourseList([]));
    UnAuthorizeRequest(e);
    return false;
  }
}

export async function CourseClassListRequest(id) {
  try {
    store.dispatch(ShowLoader());
    let URL = BaseURL + "/CourseClassList/" + id;
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(HideLoader());
    if (res.status === 200 && res.data["status"] === "success") {
      store.dispatch(SetCourseClassList(res.data["data"]));
      return true;
    } else {
      await CourseIsNotActivePopUp();
      return false;
    }
  } catch (e) {
    store.dispatch(HideLoader());
    ErrorToast("Something Went Wrong");
    UnAuthorizeRequest(e);
    return false;
  }
}

export async function CourseClassAssignmentsRequest(id) {
  try {
    store.dispatch(ShowLoader());
    let URL = BaseURL + "/CourseClassAssignments/" + id;
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(SetCourseAssignmentList(res.data["data"]));
    store.dispatch(HideLoader());
    return true;
  } catch (e) {
    store.dispatch(HideLoader());
    ErrorToast("Something Went Wrong");
    UnAuthorizeRequest(e);
    return false;
  }
}

export async function CourseClassResourcesRequest(id) {
  try {
    store.dispatch(ShowLoader());
    let URL = BaseURL + "/CourseClassResources/" + id;
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(SetCourseResourcesList(res.data["data"]));
    store.dispatch(HideLoader());
    return true;
  } catch (e) {
    store.dispatch(HideLoader());
    ErrorToast("Something Went Wrong");
    UnAuthorizeRequest(e);
    return false;
  }
}

export async function CourseClassStudyPlansRequest(id) {
  try {
    store.dispatch(ShowLoader());
    let URL = BaseURL + "/CourseClassStudyPlans/" + id;
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(SetCourseStudyPlanList(res.data["data"]));
    store.dispatch(HideLoader());
    return true;
  } catch (e) {
    store.dispatch(HideLoader());
    UnAuthorizeRequest(e);
    ErrorToast("Something Went Wrong");
    return false;
  }
}
