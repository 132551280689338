import React, {Fragment} from 'react';
import HomePage from "./page/Site/HomePage";
import AboutPage from "./page/Site/AboutPage";
import CoursesPage from "./page/Site/CoursesPage";
import ContactPage from "./page/Site/ContactPage";
import SignInPage from "./page/Site/SignInPage";
import SignUpPage from "./page/Site/SignUpPage";
import {Route, Routes} from "react-router-dom";
import VerifyOTPPage from "./page/Site/VerifyOTPPage";
import SendOTPPage from "./page/Site/SendOTPPage";
import CreatePasswordPage from "./page/Site/CreatePasswordPage";
import PrivacyPolicyPage from "./page/Site/PrivacyPolicyPage";
import TermsConditionsPage from "./page/Site/TermsConditionsPage";
import RefundPolicyPage from "./page/Site/RefundPolicyPage";
import CourseDetailsPage from "./page/Site/CourseDetailsPage";
import MyCoursesPage from "./page/Dashboard/MyCoursesPage";
import {getToken} from "./helper/SessionHelper";
import ProfilePage from "./page/Dashboard/ProfilePage";
import MyCoursesDetailsPage from "./page/Dashboard/MyCoursesDetailsPage";
import PartnerLoginPage from "./page/Partner/PartnerLoginPage";
function App() {
    if(getToken()){
        return (
            <Fragment>
                <Routes>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/privacy" element={<PrivacyPolicyPage/>}/>
                    <Route path="/terms" element={<TermsConditionsPage/>}/>
                    <Route path="/refund" element={<RefundPolicyPage/>}/>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/courses" element={<CoursesPage/>}/>
                    <Route path="/courseDetails" element={<CourseDetailsPage/>}/>
                    <Route path="/myCourses" element={<MyCoursesPage/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/myCoursesDetails" element={<MyCoursesDetailsPage/>}/>
                    <Route path="/partner" element={<PartnerLoginPage/>}/>
                </Routes>
            </Fragment>
        );
    }
    else{
        return (
            <Fragment>
                <Routes>
                    <Route path="/signin" element={<SignInPage/>}/>
                    <Route path="/signup" element={<SignUpPage/>}/>
                    <Route path="/verifyOTP" element={<VerifyOTPPage/>}/>
                    <Route path="/sendOTP" element={<SendOTPPage/>}/>
                    <Route path="/createPassword" element={<CreatePasswordPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/privacy" element={<PrivacyPolicyPage/>}/>
                    <Route path="/terms" element={<TermsConditionsPage/>}/>
                    <Route path="/refund" element={<RefundPolicyPage/>}/>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/courses" element={<CoursesPage/>}/>
                    <Route path="/courseDetails" element={<CourseDetailsPage/>}/>
                    <Route path="/classRoomHome" element={<MyCoursesPage/>}/>
                    <Route path="/partner" element={<PartnerLoginPage/>}/>

                </Routes>
            </Fragment>
        )
    }
}
export default App;