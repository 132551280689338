import Swal from "sweetalert2";
export async function CourseIsNotActivePopUp(){
    Swal.fire({
        allowOutsideClick: false,
        title: 'Verification Pending',
        text: "We have received your enroll request and appreciate you reaching out.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#21BF73',
        confirmButtonText: 'Go Back'
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href="/myCourses"
        }
    })
}