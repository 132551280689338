import React, {lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const SignIn =lazy(() => import('../../components/Site/SignIn'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const SignInPage = () => {
    return (
        <Layout>
            <Suspense fallback={<LazyLoader/>}>
                <SignIn/>
                <Footer/>
            </Suspense>
        </Layout>
    );
};
export default SignInPage;