import React, {Fragment,useRef} from 'react';
import { Navbar} from "react-bootstrap";
import logo from "../../assets/images/navlogo.svg"
import {AiOutlineEdit, AiOutlineHome, AiOutlineMenuUnfold, RiDashboardLine} from "react-icons/all";
import {NavLink} from "react-router-dom";
import {getUserDetails, removeSessions} from "../../helper/SessionHelper";
import {AiOutlineBook, AiOutlineLogout, AiOutlineUser} from "react-icons/ai";
const SideNavLayout = (props) => {
    let contentRef,sideNavRef=useRef();
    const MenuBarClickHandler = () => {
        let sideNav = sideNavRef;
        let content = contentRef;
        if (sideNav.classList.contains("side-nav-open")) {
            sideNav.classList.add("side-nav-close");
            sideNav.classList.remove("side-nav-open");
            content.classList.add("content-expand");
            content.classList.remove("content");
        } else {
            sideNav.classList.remove("side-nav-close");
            sideNav.classList.add("side-nav-open");
            content.classList.remove("content-expand");
            content.classList.add("content");
        }
    };
    return (
        <Fragment>
            <Navbar className="fixed-top px-0 bg-white shadow-sm ">
                <div className="container-fluid">
                    <Navbar.Brand >
                        <a className="icon-nav m-0 h5" onClick={MenuBarClickHandler}><AiOutlineMenuUnfold/></a>
                        <img className="nav-logo mx-2"  src={logo} alt="logo"/>
                    </Navbar.Brand>
                    <div className="user-dropdown">
                        <NavLink className="nav-link  rounded-pill text-white themeBG py-2 px-4 ms-md-3" to="/">
                            <img className="icon-nav-img" src={getUserDetails()['photo']} alt=""/> {getUserDetails()['firstName']}
                        </NavLink>
                        <div className="user-dropdown-content ">
                            <div className="mt-4 text-center">
                                <img className="icon-nav-img-lg" src={getUserDetails()['photo']} alt=""/>
                                <h6 className="fw-bold my-2 bodySmall">{getUserDetails()['firstName']}</h6>
                                <hr className="user-dropdown-divider  p-0"/>
                            </div>
                            <NavLink to="/myCourses" className="side-bar-item">
                                <AiOutlineBook className="side-bar-item-icon" />
                                <span className="side-bar-item-caption">My Courses</span>
                            </NavLink>
                            <NavLink to="/profile" className="side-bar-item">
                                <AiOutlineUser className="side-bar-item-icon" />
                                <span className="side-bar-item-caption">Profile</span>
                            </NavLink>
                            <a onClick={()=>{removeSessions()}} className="side-bar-item">
                                <AiOutlineLogout className="side-bar-item-icon" />
                                <span className="side-bar-item-caption">Logout</span>
                            </a>
                        </div>
                    </div>
                </div>
            </Navbar>


            <div ref={(div) =>{sideNavRef=div}} className="side-nav-open shadow-sm">

                <NavLink  className={(navData) => navData.isActive ? "side-bar-item-active side-bar-item mt-2" : "side-bar-item mt-2" }  to="/"  end>
                    <AiOutlineHome className="side-bar-item-icon" />
                    <span className="side-bar-item-caption">Return Home</span>
                </NavLink>

                <NavLink  className={(navData) => navData.isActive ? "side-bar-item-active side-bar-item mt-2" : "side-bar-item mt-2" }  to="/myCourses"  end>
                    <RiDashboardLine className="side-bar-item-icon" />
                    <span className="side-bar-item-caption">My Courses</span>
                </NavLink>

                <NavLink className={(navData) => navData.isActive ? "side-bar-item-active side-bar-item mt-2" : "side-bar-item mt-2" } to="/profile" >
                    <AiOutlineEdit className="side-bar-item-icon" />
                    <span className="side-bar-item-caption">Profile</span>
                </NavLink>

                <a onClick={()=>{removeSessions()}} className="side-bar-item mt-2">
                    <AiOutlineLogout className="side-bar-item-icon" />
                    <span className="side-bar-item-caption">Logout</span>
                </a>

            </div>
            <div ref={(div) => contentRef = div} className="content">
                {props.children}
            </div>

        </Fragment>
    );
};

export default SideNavLayout;