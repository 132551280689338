import React, {lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const ContactHero =lazy(() => import('../../components/Site/ContactHero'));
const ContactSection =lazy(() => import('../../components/Site/ContactSection'));
const Testimonial =lazy(() => import('../../components/Site/Testimonial'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const ContactPage = () => {
    return (
            <Layout>
                <Suspense fallback={<LazyLoader/>}>
                <ContactHero/>
                <ContactSection/>
                <Testimonial/>
                <Footer/>
                </Suspense>
            </Layout>
    );
};
export default ContactPage;