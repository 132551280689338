import React, {lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const RefundPolicyHero =lazy(() => import('../../components/Site/RefundPolicyHero'));
const RefundPolicyContent =lazy(() => import('../../components/Site/RefundPolicyContent'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const RefundPolicyPage = () => {
    return (
        <Layout>
            <Suspense fallback={<LazyLoader/>}>
            <RefundPolicyHero/>
            <RefundPolicyContent/>
            <Footer/>
            </Suspense>
        </Layout>
    );
};
export default RefundPolicyPage;