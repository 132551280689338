import {createSlice} from "@reduxjs/toolkit";
export const reviewSlice=createSlice({
    name:'review',
    initialState:{
        list:[]
    },
    reducers:{
        SetReviewList:(state,action)=>{
            state.list=action.payload
        }
    }
})
export  const {SetReviewList}=reviewSlice.actions;
export default  reviewSlice.reducer;
