import {configureStore} from "@reduxjs/toolkit";
import profileReducer from "../state-slice/profile-slice";
import settingsReducer from "../state-slice/settings-slice";
import reviewReducer from "../state-slice/review-slice";
import legalReducer from "../state-slice/legal-slice";
import recommendationReducer from "../state-slice/recommendation-slice";
import whySliceReducer from "../state-slice/why-slice";
import courseReducer from "../state-slice/course-slice";
import technologyReducer from "../state-slice/technology-slice";
import homeheroReducer from "../state-slice/homehero-slice";
import contactinfoReducer from "../state-slice/contactinfo-slice";
import footerinfoReducer from "../state-slice/footerinfo-slice";
export default configureStore({
    reducer:{
        course:courseReducer,
        settings:settingsReducer,
        profile:profileReducer,
        review:reviewReducer,
        legal:legalReducer,
        why:whySliceReducer,
        recommendation:recommendationReducer,
        technology:technologyReducer,
        homehero:homeheroReducer,
        contactinfo:contactinfoReducer,
        footerinfo:footerinfoReducer
    }
})