import {createSlice} from "@reduxjs/toolkit";
export const contactinfoSlice=createSlice({
    name:'contactinfo',
    initialState:{
        list:[]
    },
    reducers:{
        SetContactInfo:(state,action)=>{
            state.list=action.payload
        }
    }
})
export  const {SetContactInfo}=contactinfoSlice.actions;
export default  contactinfoSlice.reducer;
