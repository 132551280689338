import React, {lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const Hero =lazy(() => import('../../components/Site/Hero'));
const TopRecommended =lazy(() => import('../../components/Site/TopRecommended'));
const WhyChooseUs =lazy(() => import('../../components/Site/WhyChooseUs'));
const BrowseCourses =lazy(() => import('../../components/Site/BrowseCourses'));
const Testimonial =lazy(() => import('../../components/Site/Testimonial'));
const ContactSection =lazy(() => import('../../components/Site/ContactSection'));
const TopCourses =lazy(() => import('../../components/Site/TopCourses'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const HomePage = () => {
    return (
            <Layout>
                <Suspense fallback={<LazyLoader/>}>
                <Hero/>
                <TopRecommended/>
                <WhyChooseUs/>
                <TopCourses/>
                <BrowseCourses/>
                <Testimonial/>
                <ContactSection/>
                <Footer/>
                </Suspense>
            </Layout>
    );
};
export default HomePage;