import React, {Component, Fragment} from 'react';
import AppNavbar from "./AppNavbar";
import FullscreenLoader from "./FullscreenLoader";
class Layout extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }
    render() {
        return (
            <Fragment>
                <AppNavbar/>
                <div>
                    {this.props.children}
                </div>
                <FullscreenLoader/>
            </Fragment>
        );
    }
}

export default Layout;
