import {createSlice} from "@reduxjs/toolkit";
export const recommendationSlice=createSlice({
    name:'recommendation',
    initialState:{
        list:[]
    },
    reducers:{
        SetRecommendationList:(state,action)=>{
            state.list=action.payload
        }
    }
})
export  const {SetRecommendationList}=recommendationSlice.actions;
export default  recommendationSlice.reducer;
