import React, {lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const AboutHero =lazy(() => import('../../components/Site/AboutHero'));
const WhyChooseUs =lazy(() => import('../../components/Site/WhyChooseUs'));
const Testimonial =lazy(() => import('../../components/Site/Testimonial'));
const ContactSection =lazy(() => import('../../components/Site/ContactSection'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const AboutPage = () => {
    return (
            <Layout>
                <Suspense fallback={<LazyLoader/>}>
                    <AboutHero/>
                    <WhyChooseUs/>
                    <Testimonial/>
                    <ContactSection/>
                    <Footer/>
                </Suspense>
            </Layout>
    );
};
export default AboutPage;