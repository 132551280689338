import React, {lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const TermsConditionsHero =lazy(() => import('../../components/Site/TermsConditionsHero'));
const TermsConditionsContent =lazy(() => import('../../components/Site/TermsConditionsContent'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const TermsConditionsPage = () => {
    return (
        <Layout>
            <Suspense fallback={<LazyLoader/>}>
            <TermsConditionsHero/>
            <TermsConditionsContent/>
            <Footer/>
            </Suspense>
        </Layout>
    );
};
export default TermsConditionsPage;