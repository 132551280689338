import {createSlice} from "@reduxjs/toolkit";
export const footerinfoSlice=createSlice({
    name:'footerinfo',
    initialState:{
        list:[],
    },
    reducers:{
        SetFooterInfoList:(state,action)=>{
            state.list=action.payload
        }
    }
})
export  const {SetFooterInfoList}=footerinfoSlice.actions;
export default  footerinfoSlice.reducer;


