import React, {lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const PrivacyPolicyHero =lazy(() => import('../../components/Site/PrivacyPolicyHero'));
const PrivacyPolicyContent =lazy(() => import('../../components/Site/PrivacyPolicyContent'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const PrivacyPolicyPage = () => {
    return (
        <Layout>
            <Suspense fallback={<LazyLoader/>}>
            <PrivacyPolicyHero/>
            <PrivacyPolicyContent/>
            <Footer/>
            </Suspense>
        </Layout>
    );
};

export default PrivacyPolicyPage;