import React, {lazy, Suspense, useEffect} from 'react';
import {useSelector} from "react-redux";
import Layout from "../../components/Site/Layout";
import {CourseDetailsByIDRequest} from "../../APIRequest/SiteAPIRequest";
import LazyLoader from "../../components/Site/LazyLoader";
import {setLastLocation} from "../../helper/SessionHelper";
const CourseDetailsTabs =lazy(() => import('../../components/Site/CourseDetailsTabs'));
const CourseDetailsOverview =lazy(() => import('../../components/Site/CourseDetailsOverview'));
const CourseDetailsHero =lazy(() => import('../../components/Site/CourseDetailsHero'));
const CourseDetailsHeroPlaceholder =lazy(() => import('../../components/Site/Placeholder/CourseDetailsHeroPlaceholder'));
const CourseDetailsTabsPlaceholder =lazy(() => import('../../components/Site/Placeholder/CourseDetailsTabsPlaceholder'));
const CourseDetailsOverviewPlaceholder =lazy(() => import('../../components/Site/Placeholder/CourseDetailsOverviewPlaceholder'));
const ContactSection =lazy(() => import('../../components/Site/ContactSection'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const CourseDetailsPage = () => {

    useEffect(()=>{
        (async () => {
            const urlParams = new URLSearchParams(window.location.search);
            let id= urlParams.get('id')
            await CourseDetailsByIDRequest(id)
            setLastLocation(window.location);
        })();
    },[])

    let courseDetails=useSelector((state)=>(state.course.courseDetails));
    if(courseDetails.length>0){
        return (
            <Layout>
                <Suspense fallback={<LazyLoader/>}>
                <CourseDetailsHero/>
                <CourseDetailsTabs/>
                <CourseDetailsOverview/>
                <ContactSection/>
                <Footer/>
                </Suspense>
            </Layout>
        )
    }
    else{
        return (
            <Layout>
                <Suspense fallback={<LazyLoader/>}>
                <CourseDetailsHeroPlaceholder/>
                <CourseDetailsTabsPlaceholder/>
                <CourseDetailsOverviewPlaceholder/>
                <ContactSection/>
                <Footer/>
                </Suspense>
            </Layout>
        )
    }
};
export default CourseDetailsPage;