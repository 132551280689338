import {createSlice} from "@reduxjs/toolkit";
export const homeheroSlice=createSlice({
    name:'homehero',
    initialState:{
        list:[]
    },
    reducers:{
        SetHomeHero:(state,action)=>{
            state.list=action.payload
        }
    }
})
export  const {SetHomeHero}=homeheroSlice.actions;
export default  homeheroSlice.reducer;
