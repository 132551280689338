import React, { lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const SignUp =lazy(() => import('../../components/Site/SignUp'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const SignUpPage = () => {
    return (
        <Layout>
            <Suspense fallback={<LazyLoader/>}>
                <SignUp/>
                <Footer/>
            </Suspense>
        </Layout>
    );
};
export default SignUpPage;