import React, {lazy,Suspense} from 'react';
import SideNavLayout from "../../components/Dashboard/SideNavLayout";
import LazyLoader from "../../components/Site/LazyLoader";
const Profile =lazy(() => import('../../components/Dashboard/Profile'));
const ProfilePage = () => {
    return (
        <SideNavLayout>
            <Suspense fallback={<LazyLoader/>}>
                <Profile/>
            </Suspense>
        </SideNavLayout>
    );
};
export default ProfilePage;