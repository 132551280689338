import {createSlice} from "@reduxjs/toolkit";
export const courseSlice=createSlice({
    name:'course',
    initialState:{
        list:[],
        searchList:[],
        listByType:[],
        courseDetails:[],
        enrolledCourseList:null,
        courseClassList:[],
        courseAssignmentList:[],
        courseResourcesList:[],
        courseStudyPlanList:[]
    },
    reducers:{
        SetCourseList:(state,action)=>{
            state.list=action.payload
        },
        SetSearchList:(state,action)=>{
            state.searchList=action.payload
        },
        SetCourseListByType:(state,action)=>{
            state.listByType=action.payload
        },
        SetCourseDetails:(state,action)=>{
            state.courseDetails=action.payload
        },
        SetEnrolledCourseList:(state,action)=>{
            state.enrolledCourseList=action.payload
        },
        SetCourseClassList:(state,action)=>{
            state.courseClassList=action.payload
        },
        SetCourseAssignmentList:(state,action)=>{
            state.courseAssignmentList=action.payload
        },
        SetCourseResourcesList:(state,action)=>{
            state.courseResourcesList=action.payload
        },
        SetCourseStudyPlanList:(state,action)=>{
            state.courseStudyPlanList=action.payload
        }
    }
})
export  const {SetCourseStudyPlanList,SetCourseResourcesList,SetCourseAssignmentList,SetCourseClassList,SetCourseList,SetCourseListByType,SetCourseDetails,SetSearchList,SetEnrolledCourseList}=courseSlice.actions;
export default  courseSlice.reducer;
