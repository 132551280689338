import React, { lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const VerifyOtp =lazy(() => import('../../components/Site/VerifyOTP'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const VerifyOTPPage = () => {
    return (
       <Layout>
           <Suspense fallback={<LazyLoader/>}>
                <VerifyOtp/>
                <Footer/>
           </Suspense>
       </Layout>
    );
};
export default VerifyOTPPage;