import {createSlice} from "@reduxjs/toolkit";
export const whySlice=createSlice({
    name:'why',
    initialState:{
        list:[]
    },
    reducers:{
        SetWhy:(state,action)=>{
            state.list=action.payload
        }
    }
})
export  const {SetWhy}=whySlice.actions;
export default  whySlice.reducer;
