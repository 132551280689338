import React,{lazy, Suspense} from 'react';
import Layout from "../../components/Site/Layout";
import LazyLoader from "../../components/Site/LazyLoader";
const CreatePassword =lazy(() => import('../../components/Site/CreatePassword'));
const Footer =lazy(() => import('../../components/Site/Footer'));
const CreatePasswordPage = () => {
    return (
        <Layout>
            <Suspense fallback={<LazyLoader/>}>
                <CreatePassword/>
                <Footer/>
            </Suspense>
        </Layout>
    );
};
export default CreatePasswordPage;