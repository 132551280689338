import {createSlice} from "@reduxjs/toolkit";
export const legalSlice=createSlice({
    name:'legal',
    initialState:{
        list:[]
    },
    reducers:{
        SetLegalList:(state,action)=>{
            state.list=action.payload
        }
    }
})
export  const {SetLegalList}=legalSlice.actions;
export default  legalSlice.reducer;
